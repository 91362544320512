import Vue from "vue";
export default {
  namespaced: true,
  state: {
    loading: false,
    items: [],
    itemsHeaders: [
      {
        key: "title",
        title: "Title",
        sortable: true,
        className: ["bold"],
      },
      {
        key: "exhibition_type",
        title: "Type",
        sortable: true,
        variables: "tag",
      },
      {
        key: "status",
        title: "Status",
        sortable: true,
        className: ["md"],
        variables: "status",
      },
      {
        key: "dates",
        title: "Dates",
        sortable: true,
        className: ["capitalize"],
      },
      {
        key: "edit",
        title: "Edit",
        sortable: false,
        className: ["xsm"],
        variables: "icon",
        icon: "IconEdit",
      },
      {
        key: "delete",
        title: "Delete",
        sortable: false,
        className: ["xsm"],
        variables: "icon",
        icon: "IconDelete",
      },
    ],
    searchedItems: [],
    item: null,
    itemTimeslots: [],
    changesLoading: false,
    hasMorePages: false,
    page: 1,
    serverError: {
      slug: null,
      title: null,
      start_date: null,
      end_date: null,
    },
    itemsList: [],
    rsvpList: [],
    rsvpListHeaders: [
      {
        key: "name",
        title: "Name",
        sortable: true,
        className: ["sm"],
      },
      {
        key: "guests",
        title: "# Guests",
        sortable: true,
        className: ["ss"],
      },
      {
        key: "date",
        title: "Date",
        sortable: true,
        className: ["ss"],
      },
      {
        key: "time",
        title: "Timeslot",
        sortable: true,
        className: ["sm"],
      },
      {
        key: "email",
        title: "Email",
        sortable: true,
        className: ["sm"],
      },
      {
        key: "edit",
        title: "Edit",
        sortable: false,
        className: ["xsm"],
        variables: "icon",
        icon: "IconEdit",
      },
      {
        key: "checked_in",
        title: "Check in",
        sortable: false,
        className: ["ss", "center"],
        variables: "checkbox",
      },
    ],
    rsvp: null,
    timeslotAvailability: [],
    possibleTimes: [],
  },
  getters: {
    getLoading(state) {
      return state.loading;
    },
    getItemsHeaders(state) {
      return state.itemsHeaders;
    },
    getItems(state) {
      return state.items;
    },
    getItem(state) {
      return state.item;
    },
    getItemTimeslots(state) {
      return state.itemTimeslots;
    },
    getTimeslotAvailability(state) {
      return state.timeslotAvailability;
    },
    getChangesLoading(state) {
      return state.changesLoading;
    },
    getHasMorePages(state) {
      return state.hasMorePages;
    },
    getServerError(state) {
      return state.serverError;
    },
    getItemsList(state) {
      return state.itemsList;
    },
    getRsvpList(state) {
      return state.rsvpList;
    },
    getRsvpListHeaders(state) {
      return state.rsvpListHeaders;
    },
    getRsvp(state) {
      return state.rsvp;
    },
    getPossibleTimes(state) {
      return state.possibleTimes;
    },
  },
  mutations: {
    setLoading(state, payload) {
      state.loading = payload;
    },
    setItems(state, payload) {
      let items = [];
      items = payload.map(el => {
        return {
          ...el,
          exhibition_type: { main: el.exhibition_type, additional: [] },
          preview_image: el.home_page_image ? el.home_page_image : false,
          subtitle: el.dates,
          edit: true,
          view: true,
          tag: el.exhibition_type,
        };
      });
      if (state.page > 1) {
        state.items = state.items.concat(items);
      } else {
        state.items = items;
      }
    },
    initialItem(state) {
      state.item = null;
    },
    setItem(state, payload) {
      if (payload != null) {
        state.item = {
          ...payload,
          status: payload.status ? 1 : 0,
          copyright: payload.copyright ?? "",
          featured: Boolean(+payload.featured),
        };
      } else state.item = null;
    },
    setItemTimeslots(state, payload) {
      state.itemTimeslots = payload;
    },
    setTimeslotAvailability(state, payload) {
      state.timeslotAvailability = payload;
    },
    setChangesLoading(state, payload) {
      state.changesLoading = payload;
    },
    setPage(state, payload) {
      state.page = payload;
    },
    setHasMorePages(state, payload) {
      state.hasMorePages = payload;
    },
    setError(state, payload) {
      let serverError = state.serverError;
      if (payload != null) {
        for (let i in payload) {
          serverError[i] = payload[i][0];
        }
        state.serverError = serverError;
      } else
        state.serverError = {
          slug: payload,
          title: payload,
          start_date: payload,
          end_date: payload,
        };
    },
    setItemsList(state, payload) {
      state.itemsList = payload;
    },
    setExhibitionRsvpList(state, payload) {
      if (payload.length > 0) {
        state.rsvpList = payload.map(el => {
          return {
            ...el,
            checked_in: el.checked_in === 1 || el.checked_in === true ? true : false,
          };
        });
      } else state.rsvpList = payload;
    },
    setItemRsvp(state, payload) {
      state.rsvp = payload;
    },
    setPossibleTimes(state, payload) {
      state.possibleTimes = payload;
    },
  },
  actions: {
    async apiGetExhibitionsList({ commit }, { search = "", excludeIdx = [] }) {
      let result = false;
      let excludeIdxString = "";
      if (excludeIdx.length > 0) {
        excludeIdxString = excludeIdx
          .map(el => {
            return el.id;
          })
          .join(",");
      }
      commit("setLoading", true);
      await Vue.axios
        .get(`/api/admin/exhibitions-list?search=${search}&exclude_ids=${excludeIdxString}`)
        .then(res => res.data)
        .then(data => {
          result = true;
          commit("setItemsList", data.response);
        })
        .finally(() => {
          commit("setLoading", false);
        });
      return result;
    },
    async apiGetExhibitions(
      { commit },
      {
        status = "",
        types = "",
        sort = "title",
        order = "asc",
        page = "1",
        search = "",
        search_type = "",
        limit = "",
      }
    ) {
      let success = false;
      commit("setLoading", true);
      commit("setHasMorePages", false);
      let query = {
        status,
        types,
        sort,
        order,
        page,
        search,
        search_type,
        limit,
      };
      let queryStr = "";
      for (let key in query) {
        if (query[key] != "") {
          queryStr += `${key}=${query[key]}&`;
        }
      }
      await Vue.axios
        .get(`/api/admin/exhibitions?${queryStr}`)
        .then(res => res.data)
        .then(data => {
          success = true;
          commit("setPage", page);
          commit("setHasMorePages", data.hasMorePages);
          commit("setItems", data.response);
        })
        .finally(() => {
          commit("setLoading", false);
        });
      return success;
    },
    async apiPostExhibition({ commit }, payload) {
      let result = false;
      let data = {
        ...payload,
        status: payload.status != null ? +payload.status : "0",
      };
      commit("setChangesLoading", true);
      await Vue.axios
        .post("/api/admin/exhibition", data)
        .then(res => res.data)
        .then(data => {
          result = true;
          commit("setError", null);
          commit("setItem", data.response);
          Vue.toasted.show("Exhibition successfully created. You can now create timeslots.", {
            duration: 2000,
            type: "success",
          });
        })
        .catch(e => {
          let response = e.response;
          if (response.status === 422) {
            commit("setError", response.data.detail);
          }
        })
        .finally(() => {
          commit("setChangesLoading", false);
        });
      return result;
    },
    async apiGetExhibition({ commit }, id) {
      let result = false;
      await Vue.axios
        .get(`/api/admin/exhibition/${id}`)
        .then(res => res.data)
        .then(data => {
          result = true;
          commit("setItem", data.response);
        });
      return result;
    },
    async apiGetExhibitionTimeslots({ commit }, { id }) {
      let result = false;
      await Vue.axios
        .get(`/api/admin/exhibition/${id}/timeslots`)
        .then(res => res.data)
        .then(data => {
          result = true;
          commit("setItemTimeslots", data.response);
        });
      return result;
    },
    async apiGetExhibitionTimeslotAvailability({ commit }, { id, date }) {
      let result = false;
      await Vue.axios
        .get(`/api/admin/exhibition/${id}/timeslot?date=${date}`)
        .then(res => res.data)
        .then(data => {
          result = true;
          commit("setTimeslotAvailability", data.response.times);
        });
      return result;
    },
    async apiPostExhibitionTimeslotAvailability({ commit }, { id, date, times }) {
      let result = false;
      await Vue.axios
        .post(`/api/admin/exhibition/${id}/timeslot?date=${date}`, { times })
        .then(res => res.data)
        .then(() => {
          result = true;
        });
      return result;
    },

    async apiPutExhibition({ commit }, payload) {
      commit("setChangesLoading", true);
      let result = false;
      let data = {
        ...payload,
        status: payload.status != null ? +payload.status : "0",
      };
      await Vue.axios
        .put(`/api/admin/exhibition/${payload.id}`, data)
        .then(res => res.data)
        .then(data => {
          result = true;
          commit("setError", null);
          Vue.toasted.show(data.message, {
            duration: 2000,
            type: "success",
          });
        })
        .catch(e => {
          let response = e.response;
          if (response.status === 422) {
            commit("setError", response.data.detail);
          }
        })
        .finally(() => {
          commit("setChangesLoading", false);
        });
      return result;
    },
    async apiDeleteExhibition({ commit }, id) {
      let result = false;
      await Vue.axios
        .delete(`/api/admin/exhibition/${id}`)
        .then(res => res.data)
        .then(data => {
          result = true;
          Vue.toasted.show(data.message, {
            duration: 2000,
            type: "success",
          });
        });
      return result;
    },
    async apiGetExhibitionRsvpList({ commit }, { id, date = '', time = '', sort = "name", order = "asc" }) {
      let success = false;
      commit("setLoading", true);
      let query = {
        date,
        time,
        sort,
        order,
      };
      let queryStr = "";
      for (let key in query) {
        if (query[key] != "") {
          queryStr += `${key}=${query[key]}&`;
        }
      }
      await Vue.axios
        .get(`/api/admin/exhibition/${id}/rsvps?${queryStr}`)
        .then(res => res.data)
        .then(data => {
          success = true;
          commit("setExhibitionRsvpList", data.response);
        })
        .finally(() => {
          commit("setLoading", false);
        });
      return success;
    },
    async apiGetRsvp({ commit }, { entity, rsvp }) {
      let result = false;
      await Vue.axios
        .get(`/api/admin/exhibition/${entity}/rsvp/${rsvp}`)
        .then(res => res.data)
        .then(data => {
          result = true;
          commit("setItemRsvp", data.response);
        });
      return result;
    },
    async apiPutRsvp({ commit }, { entity, rsvp, data }) {
      let result = false;
      await Vue.axios.put(`/api/admin/exhibition/${entity}/rsvp/${rsvp}`, data).then(() => {
        result = true;
        commit("setItemRsvp", null);
        Vue.toasted.show("Reservation was successfully updated", {
          duration: 2000,
          type: "success",
        });
      });
      return result;
    },
    async apiPutRsvpCheck({ commit }, { entity, rsvp }) {
      let result = false;
      await Vue.axios.put(`/api/admin/exhibition/${entity}/rsvp/${rsvp}/check-in`).then(() => {
        result = true;
        commit("setItemRsvp", null);
        Vue.toasted.show("Reservation was successfully updated", {
          duration: 2000,
          type: "success",
        });
      });
      return result;
    },
    async apiDeleteRsvp({ commit }, { entity, rsvp }) {
      let result = false;
      await Vue.axios
        .delete(`/api/admin/exhibition/${entity}/rsvp/${rsvp}`)
        .then(res => res.data)
        .then(data => {
          result = true;
          commit("setItemRsvp", null);
          Vue.toasted.show("Reservation was successfully deleted", {
            duration: 2000,
            type: "success",
          });
        });
      return result;
    },
    async apiGetExhibitionTimeslotTimes({ commit }) {
      let result = false;
      await Vue.axios
        .get(`/api/admin/exhibition/timeslot-times`)
        .then(res => res.data)
        .then(data => {
          result = true;
          commit("setPossibleTimes", data.response);
        });
      return result;
    },
    clearExhibition({ commit }) {
      commit("setItem", null);
      commit("setExhibitionRsvpList", []);
      commit("setTimeslotAvailability", []);
      commit("setItemTimeslots", []);
    },
  },
};


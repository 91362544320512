import Vue from "vue";
export default {
  namespaced: true,
  state: {
    serverError: {
      title: null,
    },
    types: [],
    typeList: [],
    looseThread: {},
  },
  getters: {
    getServerError(state) {
      return state.serverError;
    },
    getTypes(state) {
      return state.types;
    },
    getTypeList(state) {
      return state.typeList;
    },
    getLooseThread(state) {
      return state.looseThread;
    },
  },
  mutations: {
    setError(state, payload) {
      let serverError = state.serverError;
      if (payload != null) {
        for (let i in payload) {
          serverError[i] = payload[i][0];
        }
        state.serverError = serverError;
      } else
        state.serverError = {
          title: payload,
        };
    },
    setTypeList(state, payload) {
      const looseThreads = [];
      payload.map(item => {
        looseThreads.push({
          ...item,
          active: false,
        });
      });
      if (looseThreads.length) looseThreads[0].active = true;
      state.typeList = looseThreads;
    },
    setTypes(state, payload) {
      const types = [];
      payload.map(item => {
        types.push({
          title: item.title[0].toUpperCase() + item.title.slice(1),
          key: item.key,
          active: false,
        });
      });
      types[0].active = true;
      state.types = types;
    },
    setLooseThread(state, payload) {
      state.looseThread = payload;
    },
    setTypeActive(state, key) {
      state.types.map(item => {
        if (item.key === key) item.active = true;
        else item.active = false;
      });
    },
    setTypeListActive(state, key) {
      state.typeList.map(item => {
        if (item.key === key) item.active = true;
        else item.active = false;
      });
    },
  },
  actions: {
    async apiLooseThreadsTypes({ commit }) {
      let success = false;
      await Vue.axios
        .get(`/api/public/loose-threads/types`)
        .then(res => res.data)
        .then(data => {
          commit("setTypes", data.response ?? []);
          success = true;
          commit("setError", null);
        })
        .catch(e => {
          let response = e.response;
          if (response.status === 422) {
            commit("setError", response.data.details.message);
          }
        });
      return success;
    },
    async apiLooseThreadsTypeList({ commit }, type) {
      let success = false;
      await Vue.axios
        .get(`/api/public/loose-threads/list/${type}`)
        .then(res => res.data)
        .then(data => {
          commit("setTypeList", data.response);
          success = true;
          commit("setError", null);
        })
        .catch(e => {
          let response = e.response;
          if (response.status === 422) {
            commit("setError", response.data.details.message);
          }
        });
      return success;
    },
    async apiLooseThread({ commit }, { type, slug }) {
      let success = false;
      await Vue.axios
        .get(`/api/public/loose-threads/${type}/${slug}`)
        .then(res => res.data)
        .then(data => {
          commit("setLooseThread", data.response);
          success = true;
          commit("setError", null);
        })
        .catch(e => {
          let response = e.response;
          if (response.status === 422) {
            commit("setError", response.data.details.message);
          }
        });
      return success;
    },
  },
};

import Vue from "vue";
export default {
  namespaced: true,
  state: {
    loading: false,
    items: [],
    itemsHeaders: [
      {
        key: "last_name",
        title: "Artist Name",
        sortable: true,
        className: ["capitalize"],
      },
      {
        key: "regions",
        title: "Countries/Regions",
        sortable: true,
        className: ["md"],
        variables: "tag",
      },
      {
        key: "status",
        title: "Status",
        sortable: true,
        className: ["md"],
        variables: "status",
      },
      {
        key: "indent",
        className: ["indent"],
      },
      {
        key: "edit",
        title: "Edit",
        sortable: false,
        className: ["xsm", "center"],
        variables: "icon",
        icon: "IconEdit",
      },
      {
        key: "delete",
        title: "Delete",
        sortable: false,
        className: ["xsm", "center"],
        variables: "icon",
        icon: "IconDelete",
      },
      {
        key: "view",
        title: "View",
        sortable: false,
        className: ["xsm", "center"],
        variables: "icon",
        icon: "IconEye",
      },
    ],
    publicationsHeaders: [
      {
        key: "title",
        title: "Title",
        sortable: true,
        className: ["capitalize"],
      },
      {
        key: "type",
        title: "Type",
        sortable: true,
        className: ["capitalize", "md"],
      },
      {
        key: "author",
        title: "Author",
        sortable: true,
        className: ["md"],
      },
      {
        key: "indent",
        className: ["indent", "indent-xs"],
      },
      {
        key: "reorder",
        title: "Reorder",
        sortable: false,
        className: ["s", "center"],
        variables: "icon",
        icon: "IconReorder",
      },
    ],
    publications: [],
    searchedItems: [],
    item: null,
    changesLoading: false,
    hasMorePages: false,
    page: 1,
    serverError: {
      slug: null,
    },
  },
  getters: {
    getLoading(state) {
      return state.loading;
    },
    getItemsHeaders(state) {
      return state.itemsHeaders;
    },
    getPublicationsHeaders(state) {
      return state.publicationsHeaders;
    },
    getPublications(state) {
      return state.publications;
    },
    getItems(state) {
      return state.items;
    },
    getSearchedArtists(state) {
      return state.searchedItems;
    },
    getItem(state) {
      return state.item;
    },
    getChangesLoading(state) {
      return state.changesLoading;
    },
    getHasMorePages(state) {
      return state.hasMorePages;
    },
    getServerError(state) {
      return state.serverError;
    },
  },
  mutations: {
    setLoading(state, payload) {
      state.loading = payload;
    },
    setItems(state, payload) {
      let items = [];
      items = payload.map(el => {
        let regions = {
          main: "",
          additional: [],
        };
        if (el.regions.length > 0) {
          regions.main = el.regions[0].title;
          regions.additional = el.regions.slice(1);
        }
        return {
          ...el,
          preview_image: el.header_image ? el.header_image : false,
          title: `${el.last_name}, ${el.first_name}`,
          subtitle: el.region,
          last_name: `${el.last_name}, ${el.first_name}`,
          edit: true,
          view: true,
          regions,
        };
      });
      if (state.page > 1) {
        state.items = state.items.concat(items);
      } else {
        state.items = items;
      }
    },
    setPublications(state, payload) {
      state.publications = payload.map(el => {
        return (el = { ...el, orderType: el.type });
      });
    },
    setSearchedItems(state, payload) {
      state.searchedItems = payload.map(el => {
        return {
          id: el.id,
          title: `${el.last_name} ${el.first_name}`,
        };
      });
    },
    initialItem(state) {
      state.item = null;
    },
    setItem(state, payload) {
      state.item = payload;
    },
    setChangesLoading(state, payload) {
      state.changesLoading = payload;
    },
    setPage(state, payload) {
      state.page = payload;
    },
    setHasMorePages(state, payload) {
      state.hasMorePages = payload;
    },
    setError(state, payload) {
      let serverError = state.serverError;
      if (payload != null) {
        for (let i in payload) {
          serverError[i] = payload[i][0];
        }
        state.serverError = serverError;
      } else
        state.serverError = {
          slug: null,
        };
    },
  },
  actions: {
    async apiGetArtists(
      { commit },
      {
        status = "",
        regions = "",
        sort = "last_name",
        order = "asc",
        page = "1",
        search = "",
        search_type = "",
        limit = "",
      }
    ) {
      let success = false;
      commit("setLoading", true);
      commit("setHasMorePages", false);
      let query = {
        status,
        regions,
        sort,
        order,
        page,
        search,
        search_type,
        limit,
      };
      let queryStr = "";
      for (let key in query) {
        if (query[key] != "") {
          queryStr += `${key}=${query[key]}&`;
        }
      }
      await Vue.axios
        .get(`/api/admin/artists?${queryStr}`)
        .then(res => res.data)
        .then(data => {
          success = true;
          commit("setPage", page);
          commit("setHasMorePages", data.hasMorePages);
          commit("setItems", data.response);
        })
        .finally(() => {
          commit("setLoading", false);
        });
      return success;
    },
    async apiGetSearchedArtists({ commit }, { search = "", excludeIdx = [], status = null }) {
      let success = false,
        limit = "all";
      let excludeIdxString = "";
      if (excludeIdx.length > 0) {
        excludeIdxString = excludeIdx
          .map(el => {
            return el.id;
          })
          .join(",");
      }
      commit("setChangesLoading", true);
      const url =
        status != null
          ? `/api/admin/artists?limit=${limit}&search=${search}&exclude_ids=${excludeIdxString}&status=${status}`
          : `/api/admin/artists?limit=${limit}&search=${search}&exclude_ids=${excludeIdxString}`;

      await Vue.axios
        .get(url)
        .then(res => res.data)
        .then(data => {
          success = true;
          commit("setSearchedItems", data.response);
        })
        .finally(() => {
          commit("setChangesLoading", false);
        });
      return success;
    },
    async apiPostArtist({ commit }, payload) {
      let result = false;
      let data = {
        ...payload,
        status: payload.status != null ? payload.status : "0",
      };
      commit("setChangesLoading", true);
      await Vue.axios
        .post("/api/admin/artist", data)
        .then(res => res.data)
        .then(data => {
          result = true;
          commit("setError", null);
          Vue.toasted.show(data.message, {
            duration: 2000,
            type: "success",
          });
        })
        .catch(e => {
          let response = e.response;
          if (response.status === 422) {
            commit("setError", response.data.detail);
          }
        })
        .finally(() => {
          commit("setChangesLoading", false);
        });
      return result;
    },
    async apiGetArtist({ commit }, id) {
      let result = false;
      await Vue.axios
        .get(`/api/admin/artist/${id}`)
        .then(res => res.data)
        .then(data => {
          result = true;
          commit("setItem", data.response);
        });
      return result;
    },
    async apiPutArtist({ commit }, payload) {
      commit("setChangesLoading", true);
      let result = false;
      await Vue.axios
        .put(`/api/admin/artist/${payload.id}`, payload)
        .then(res => res.data)
        .then(data => {
          result = true;
          commit("setError", null);
          Vue.toasted.show(data.message, {
            duration: 2000,
            type: "success",
          });
        })
        .catch(e => {
          let response = e.response;
          if (response.status === 422) {
            commit("setError", response.data.detail);
          }
        })
        .finally(() => {
          commit("setChangesLoading", false);
        });
      return result;
    },
    async apiDeleteArtist({ commit }, id) {
      let result = false;
      await Vue.axios
        .delete(`/api/admin/artist/${id}`)
        .then(res => res.data)
        .then(data => {
          result = true;
          Vue.toasted.show(data.message, {
            duration: 2000,
            type: "success",
          });
        })
        .catch(e => {
          let response = e.response;
          if (response.status === 403) {
            Vue.swal({
              title: `${response.data.detail[0].message}!`,
              confirmButtonText: "OK",
            });
          }
        });
      return result;
    },
    async apiGetPublications({ commit }, { id, sort = "title", order = "asc" }) {
      let success = false;
      let query = {
        sort,
        order,
      };
      let queryStr = "";
      for (let key in query) {
        if (query[key] != "") {
          queryStr += `${key}=${query[key]}&`;
        }
      }
      await Vue.axios
        .get(`/api/admin/artist/${id}/publications?${queryStr}`)
        .then(res => res.data)
        .then(data => {
          success = true;
          commit("setPublications", data.response);
        });
      return success;
    },
    async apiPutChoseOrder({ commit }, { id, publications }) {
      let list = [...publications];
      let result = false;
      await Vue.axios
        .put(`/api/admin/artist/${id}/publications`, { list })
        .then(res => res.data)
        .then(data => {
          result = true;
          Vue.toasted.show(data.message, {
            duration: 2000,
            type: "success",
          });
        });
      return result;
    },
  },
};


export function infiniteScroll(el) {
  let result = false;
  const listElm = document.querySelector(el);
  const sizeScrollActive = 100;
  if (listElm.scrollTop + listElm.clientHeight + sizeScrollActive >= listElm.scrollHeight) {
    result = true;
  }
  return result;
}

export function initials(name, lastName) {
  let letterName = "",
    letterLastName = "";
  letterName = name[0].toUpperCase();
  if (lastName) {
    letterLastName = lastName[0].toUpperCase();
  }
  return letterName + letterLastName;
}

export function articlesSlug(type, slug) {
  switch (type) {
    case "arttextstyle":
      return "/media/arttextstyle/" + slug;
      break;
    case "press release":
      return "/media/press-releases/" + slug;
      break;
    case "in the news":
      return "/media/in-the-news/" + slug;
      break;
  }
}

export function eventsSlug(type, slug) {
  switch (type) {
    case "event":
      return "/events/events/" + slug;
      break;
    case "exhibition":
      return "/exhibitions/" + slug;
      break;
  }
}

export function sortedColumns(list, columnsAmount) {
  if (list.length > 0) {
    const result = [];
    const itemsAmount = list.length;
    let itemsAmountLeft = itemsAmount;
    let columnsAmountLeft = columnsAmount;
    let latestItemStartIndex = 0;

    let maxItemsInColumn = Math.ceil(itemsAmount / columnsAmount);

    for (let columnIndex = 0; columnIndex < columnsAmount; columnIndex++) {
      const itemsAmountWillLeft = itemsAmountLeft - maxItemsInColumn;
      const columnsAmountWilLeft = columnsAmountLeft - 1;

      if (itemsAmountWillLeft / columnsAmountWilLeft < maxItemsInColumn - 1) {
        maxItemsInColumn--;
      }

      const itemEndIndex = latestItemStartIndex + maxItemsInColumn;
      result[columnIndex] = list.slice(latestItemStartIndex, itemEndIndex);

      latestItemStartIndex = itemEndIndex;
      itemsAmountLeft -= maxItemsInColumn;
      columnsAmountLeft--;
    }
    return result;
  } else return list
}


export const STATUSES = {
  DONATED: "donated",
  FOR_SALE: "for sale",
  FOR_SALE_NOT_INVENTORY: "for sale (not in inventory)",
  GIFTED: "gifted",
  ON_LOAN: "on loan",
  RETURNED_TO_ARTIST: "returned to artist",
  ON_HOLD: "on hold",
  UNAVAILABLE: "unavailable",
  SOLD: "sold",
};
import Vue from "vue";
import general from "../private/general.js";

function getArtworkSize(artwork) {
  let size = artwork.attributes.map(attribute => {
    let text = "";
    if (attribute.height) text += `${attribute.height}`;
    if (attribute.width) {
      if (attribute.height) text += " x ";
      text += `${attribute.width}`;
    }
    if (attribute.depth) {
      if (attribute.width || attribute.height) text += " x ";
      text += `${attribute.depth}`;
    }
    return text;
  });
  return size;
}

export default {
  namespaced: true,
  state: {
    serverError: {
      title: null,
    },
    shopList: [],
    featuredArtwork: {},
    hasMorePages: false,
    artPiece: { product_images: [], size: [] },
    randomArtByArtist: [],
    statePage: {
      page: null,
      scroll: null,
    },
  },

  getters: {
    getServerError(state) {
      return state.serverError;
    },
    getShopList(state) {
      return state.shopList;
    },
    getFeaturedArtwork(state) {
      return state.featuredArtwork;
    },
    getHasMorePages(state) {
      return state.hasMorePages;
    },
    getArtPiece(state) {
      return state.artPiece;
    },
    getRandomArtByArtist(state) {
      return state.randomArtByArtist;
    },
    getStatePage(state) {
      return state.statePage;
    },
  },

  mutations: {
    setError(state, payload) {
      let serverError = state.serverError;
      if (payload != null) {
        for (let i in payload) {
          serverError[i] = payload[i][0];
        }
        state.serverError = serverError;
      } else
        state.serverError = {
          title: payload,
        };
    },
    setShopList(state, { data, page }) {
      const newData = data.map(art => {
        return {
          ...art,
          price: art.price ? art.price.toLocaleString("en-US") : art.price,
        };
      });

      if (page > 1) {
        state.shopList.push(...newData);
        //state.shopList = state.shopList.concat(newData);
      } else {
        state.shopList = newData;
      }
    },
    setFeaturedArtwork(state, payload) {
      state.featuredArtwork = payload;
    },
    setHasMorePages(state, payload) {
      state.hasMorePages = payload;
    },
    setArtPiece(state, payload) {
      const art = {
        ...payload,
        price: payload.price ? payload.price.toLocaleString("en-US") : payload.price,
        size: getArtworkSize(payload),
      };
      state.artPiece = art;
    },
    setRandomArtByArtist(state, payload) {
      const list = payload.map(art => {
        return {
          ...art,
          price: art.price ? art.price.toLocaleString("en-US") : art.price,
        };
      });
      state.randomArtByArtist = list;
    },
    setStatePage(state, payload) {
      state.statePage = payload;
    },
    resetArtwork(state) {
      state.artPiece = { product_images: [], size: [] };
    },
  },

  actions: {
    async apiFormsInquire({ commit }, payload) {
      let success = false;
      await Vue.axios
        .post(`/api/public/forms/inquire`, payload)
        .then(res => res.data)
        .then(data => {
          success = true;
          commit("setError", null);
          Vue.toasted.show(data.message, {
            duration: 2000,
            type: "success",
          });
        })
        .catch(e => {
          let response = e.response;
          if (response.status === 422) {
            commit("setError", response.data.details.message);
          }
        });
      return success;
    },
    async apiFormsVirtualPlacement({ commit }, payload) {
      let success = false;
      const data = new FormData();
      data.append("art", payload.art);
      data.append("name", payload.name);
      data.append("email", payload.email);
      data.append("phone", payload.phone);
      data.append("service_location", payload.service_location);
      data.append("height", payload.height);
      data.append("width", payload.width);
      data.append("length", payload.length);
      data.append("description", payload.description);
      payload.images.map((image, index) => {
        data.append(`images[${index}]`, image);
      });
      await Vue.axios
        .post(`/api/public/forms/specific-art-piece-virtual-placement`, data)
        .then(res => res.data)
        .then(data => {
          success = true;
          commit("setError", null);
          Vue.toasted.show(data.message, {
            duration: 2000,
            type: "success",
          });
        })
        .catch(e => {
          let response = e.response;
          if (response.status === 422) {
            commit("setError", response.data.details.message);
          }
        });
      return success;
    },

    async apiGetShopList(
      { commit },
      {
        page = "1",
        sort = "",
        art_types = "",
        materials = "",
        price_start = "",
        price_end = "",
        periods = "",
        colors = "",
        regions = "",
        availabilities = "",
        width_start = "",
        width_end = "",
        height_start = "",
        height_end = "",
        depth_start = "",
        depth_end = "",
      }
    ) {
      let query = {
        page,
        sort,
        art_types,
        materials,
        price_start,
        price_end,
        periods,
        colors,
        regions,
        availabilities,
        width_start,
        width_end,
        height_start,
        height_end,
        depth_start,
        depth_end,
      };
      let queryStr = "";
      let success = false;
      for (let key in query) {
        if (query[key]) {
          queryStr += `${key}=${query[key]}&`;
        }
      }
      await Vue.axios.get(`/api/public/artworks/shop-page?${queryStr}`).then(response => {
        success = true;
        commit("setShopList", { data: response.data.response, page });
        commit("setFeaturedArtwork", response.data.featuredArtwork);
        commit("setHasMorePages", response.data.hasMorePages);
      });
      return success;
    },

    async apiGetArtPiece({ commit }, slug) {
      await Vue.axios.get(`/api/public/artworks/${slug}`).then(response => {
        commit("setArtPiece", response?.data?.response ?? {});
        commit("setRandomArtByArtist", response?.data?.threeRandomByArtist ?? []);
      });
    },
  },
};


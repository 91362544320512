import Vue from "vue";

const getYearsLocation = payload => {
  let yearsLocation = "";
  if (payload.birth_year && payload.death_year) {
    yearsLocation = `${payload.birth_year} - ${payload.death_year}`;
    if (payload.location) {
      yearsLocation = `${payload.birth_year} - ${payload.death_year}, ${payload.location}`;
    }
    return yearsLocation;
  }
  if (payload.birth_year) {
    yearsLocation = `Born ${payload.birth_year}`;
    if (payload.location) {
      yearsLocation = `Born ${payload.birth_year}, ${payload.location}`;
    }
    return yearsLocation;
  }
  if (payload.death_year) {
    yearsLocation = `Died ${payload.death_year}`;
    if (payload.location) {
      yearsLocation = `Died ${payload.death_year}, ${payload.location}`;
    }
    return yearsLocation;
  }
  if (payload.location) {
    yearsLocation = `${payload.location}`;
  }
  return yearsLocation;
};

export default {
  namespaced: true,
  state: {
    artists: [],
    hasMorePages: false,
    artist: {},
    hasArtworks: false,
    hasExhibition: false,
    hasPublications: false,
    relatedArtworks: [],
    hasMoreArtworksPages: false,
    relatedPublications: [],
    hasMorePublicationsPages: false,
    relatedExhibitions: [],
    hasMoreExhibitionsPages: false,
    artistsList: [],
  },
  getters: {
    getArtists(state) {
      return state.artists;
    },
    getArtistsList(state) {
      return state.artistsList;
    },
    getHasMorePages(state) {
      return state.hasMorePages;
    },
    getArtist(state) {
      return state.artist;
    },
    getHasArtworks(state) {
      return state.hasArtworks;
    },
    getHasExhibition(state) {
      return state.hasExhibition;
    },
    getHasPublications(state) {
      return state.hasPublications;
    },
    getRelatedArtworks(state) {
      return state.relatedArtworks;
    },
    getHasMoreArtworksPages(state) {
      return state.hasMoreArtworksPages;
    },
    getRelatedPublications(state) {
      return state.relatedPublications;
    },
    getHasMorePublicationsPages(state) {
      return state.hasMorePublicationsPages;
    },
    getRelatedExhibitions(state) {
      return state.relatedExhibitions;
    },
    getHasMoreExhibitionsPages(state) {
      return state.hasMoreExhibitionsPages;
    },
  },
  mutations: {
    setArtists(state, { data, page }) {
      if (page > 1) {
        state.artists = state.artists.concat(data);
      } else {
        state.artists = data;
      }
    },
    setHasMorePages(state, payload) {
      state.hasMorePages = payload;
    },
    setArtist(state, payload) {
      const artist = {
        ...payload,
        yearsLocation: getYearsLocation(payload),
      };
      state.artist = artist;
    },
    setArtistsList(state, { data }) {
      state.artistsList = data;
    },
    setHasArtworks(state, payload) {
      state.hasArtworks = payload;
    },
    setHasExhibition(state, payload) {
      state.hasExhibition = payload;
    },
    setHasPublications(state, payload) {
      state.hasPublications = payload;
    },
    setRelatedArtworks(state, { data, page }) {
      if (page > 1) {
        state.relatedArtworks = state.relatedArtworks.concat(data);
      } else {
        state.relatedArtworks = data;
      }
    },
    setHasMoreArtworksPages(state, payload) {
      state.hasMoreArtworksPages = payload;
    },
    setRelatedPublications(state, { data, page }) {
      if (page > 1) {
        state.relatedPublications = state.relatedPublications.concat(data);
      } else {
        state.relatedPublications = data;
      }
    },
    setHasMorePublicationsPages(state, payload) {
      state.hasMorePublicationsPages = payload;
    },
    setRelatedExhibitions(state, { data, page }) {
      if (page > 1) {
        state.relatedExhibitions = state.relatedExhibitions.concat(data);
      } else {
        state.relatedExhibitions = data;
      }
    },
    setHasMoreExhibitionsPages(state, payload) {
      state.hasMoreExhibitionsPages = payload;
    },
  },
  actions: {
    async apiGetArtists(
      { commit },
      { sort = "", regions = "", page = "1", market = "", viewType = "list" }
    ) {
      let query = {
        sort,
        regions,
        page: viewType === "list" ? "all" : page,
        market,
      };
      let queryStr = "";
      let success = false;
      for (let key in query) {
        if (query[key]) {
          queryStr += `${key}=${query[key]}&`;
        }
      }
      await Vue.axios.get(`/api/public/artists?${queryStr}`).then(response => {
        success = true;
        commit("setArtists", { data: response.data.response, page });
        commit("setHasMorePages", response?.data.hasMorePages);
      });
      return success;
    },

    async apiGetArtistsList({ commit }, { market = "" }) {
      let query = {
        market,
      };
      let queryStr = "";
      for (let key in query) {
        if (query[key]) {
          queryStr += `${key}=${query[key]}&`;
        }
      }

      await Vue.axios.get(`/api/public/artists-list?${queryStr}`).then(response => {
        commit("setArtistsList", { data: response.data.response });
      });
    },

    async apiGetSpecificArtist({ commit }, { slug }) {
      await Vue.axios.get(`/api/public/artists/${slug}`).then(response => {
        commit("setArtist", response?.data.response ?? {});
        commit("setHasArtworks", response?.data.has_artwors);
        commit("setHasExhibition", response?.data.has_exhibition);
        commit("setHasPublications", response?.data.has_publications);
      });
    },

    async apiGetRelatedArtworks(
      { commit },
      {
        sort = "",
        page = "1",
        art_types = "",
        materials = "",
        price_start = "",
        price_end = "",
        periods = "",
        colors = "",
        regions = "",
        availabilities = "",
        width_start = "",
        width_end = "",
        height_start = "",
        height_end = "",
        depth_start = "",
        depth_end = "",
        slug,
      }
    ) {
      let query = {
        sort,
        page,
        art_types,
        materials,
        price_start,
        price_end,
        periods,
        colors,
        regions,
        availabilities,
        width_start,
        width_end,
        height_start,
        height_end,
        depth_start,
        depth_end,
      };
      let queryStr = "";
      let success = false;
      for (let key in query) {
        if (query[key]) {
          queryStr += `${key}=${query[key]}&`;
        }
      }
      await Vue.axios.get(`/api/public/artists/${slug}/artworks?${queryStr}`).then(response => {
        success = true;
        commit("setRelatedArtworks", { data: response.data.response, page });
        commit("setHasMoreArtworksPages", response.data.hasMorePages);
      });
      return success;
    },

    async apiGetRelatedPublications({ commit }, { slug, page }) {
      await Vue.axios.get(`/api/public/artists/${slug}/publications`).then(response => {
        commit("setRelatedPublications", { data: response.data.response, page });
        commit("setHasMorePublicationsPages", response.data.hasMorePages);
      });
    },

    async apiGetRelatedExhibitions({ commit }, { slug, page }) {
      await Vue.axios.get(`/api/public/artists/${slug}/exhibitions?page=${page}`).then(response => {
        commit("setRelatedExhibitions", { data: response.data.response, page });
        commit("setHasMoreExhibitionsPages", response.data.hasMorePages);
      });
    },
  },
};


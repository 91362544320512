import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./axios";
import VueMoment from "vue-moment";
import moment from "moment-timezone";
import "./filters";
import VueHead from "vue-head";

Vue.use(VueHead, {
  separator: "|",
  complement: "browngrotta arts",
});

Vue.use(VueMoment, {
  moment,
});

import "./registerServiceWorker";
import "./assets/style.scss";

import BaseLayout from "@/layouts/BaseLayout.vue";
Vue.component("base-layout", BaseLayout);
import AdminLayout from "@/layouts/AdminLayout.vue";
Vue.component("admin-layout", AdminLayout);
import EmptyLayout from "@/layouts/EmptyLayout.vue";
Vue.component("empty-layout", EmptyLayout);

import "./validate.js";
import { ValidationProvider } from "vee-validate";
Vue.component("ValidationProvider", ValidationProvider);

import VueProgressBar from "vue-progressbar";
Vue.use(VueProgressBar, {
  color: "#c5e1ed",
  failedColor: "#6DB5D1",
  thickness: "2px",
  position: "absolute",
  transition: {
    speed: "0.2s",
    opacity: "0.6s",
    termination: 300,
  },
  autoFinish: false,
});

import Toasted from "vue-toasted";
Vue.use(Toasted, {
  containerClass: "toasted-container--collect",
  position: "top-center",
  duration: "1500",
  keepOnHover: true,
});

import VueSocialSharing from "vue-social-sharing";
Vue.use(VueSocialSharing);

var VueScrollTo = require("vue-scrollto");
Vue.use(VueScrollTo, {
  container: "body",
  duration: 500,
  easing: "ease",
  offset: 0,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true,
});

import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
Vue.use(VueSweetalert2, {
  showCloseButton: true,
  customClass: {
    container: "notification",
    popup: "notification__popup",
    confirmButton: "btn",
    cancelButton: "btn",
    denyButton: "btn",
  },
});

import InfiniteLoading from "vue-infinite-loading";
Vue.use(InfiniteLoading, {
  slots: {
    noMore: "",
    noResults: "",
  },
});

import "@/plugins/google-analytics";
import "@/plugins/google-tag-manager";

Vue.config.productionTip = false;

router.afterEach((to) => {
  if (to.meta.layout !== "admin") {
    (function (c, l, a, r, i, t, y) {
      c[a] =
        c[a] ||
        function () {
          (c[a].q = c[a].q || []).push(arguments);
        };
      t = l.createElement(r);
      t.async = 1;
      t.src = "https://www.clarity.ms/tag/" + i;
      y = l.getElementsByTagName(r)[0];
      y.parentNode.insertBefore(t, y);
    })(window, document, "clarity", "script", "q7pqtcfdrk");
  }
});

new Vue({
  router,
  store,
  render: h => h(App),
  mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
}).$mount("#app");

import Vue from "vue";
export default {
  namespaced: true,
  state: {
    loading: false,
    exhibitions: [],
    featuredExhibition: {
      slug: null,
    },
    hasMorePages: false,
    exhibition: {
      sponsors: [],
    },
    has_artworks_and_artists: false,
    features: [],
    hasMoreFeatures: false,
    filters: [],
    timeslotDatesAvailability: [],
    timeslotAvailability: [],
  },
  getters: {
    getExhibitions(state) {
      return state.exhibitions;
    },
    getFeaturedExhibition(state) {
      return state.featuredExhibition;
    },
    getLoading(state) {
      return state.loading;
    },
    getHasMorePages(state) {
      return state.hasMorePages;
    },
    getExhibition(state) {
      return state.exhibition;
    },
    getFeature(state) {
      return state.has_artworks_and_artists;
    },
    getFeatures(state) {
      return state.features;
    },
    getHasMoreFeatures(state) {
      return state.hasMoreFeatures;
    },
    getFilters(state) {
      return state.filters;
    },
    getTimeslotAvailability(state) {
      return state.timeslotAvailability;
    },
    getTimeslotDatesAvailability(state) {
      return state.timeslotDatesAvailability;
    },
  },
  mutations: {
    setLoading(state, payload) {
      state.loading = payload;
    },
    setExhibitions(state, { data, page }) {
      if (page > 1) {
        state.exhibitions = state.exhibitions.concat(data);
      } else {
        state.exhibitions = data;
      }
    },
    setHasMorePages(state, payload) {
      state.hasMorePages = payload;
    },
    setFeaturedExhibition(state, payload) {
      state.featuredExhibition = { ...payload, slug: payload?.slug ? payload.slug : null };
    },
    setExhibition(state, payload) {
      state.exhibition = payload;
    },
    setFeature(state, payload) {
      state.has_artworks_and_artists = payload;
    },

    setFeatures(state, { features, pages }) {
      state.features = features ?? [];
      state.pages = pages || false;
    },

    setFilters(state, payload) {
      let filters = [
        {
          id: "",
          title: "ALL EXHIBITIONS",
          selected: true,
        },
      ];
      payload.forEach(el => {
        filters.push({ ...el, selected: el.selected ? el.selected : false });
      });
      state.filters = filters;
    },
    changeFilterValue(state, payload) {
      let filters = state.filters;
      filters.forEach(el => {
        el.selected = el.id === payload.id ? true : false;
      });
      state.filters = filters;
    },
    setTimeslotAvailability(state, payload) {
      state.timeslotAvailability = payload;
    },
    setTimeslotDatesAvailability(state, payload) {
      state.timeslotDatesAvailability = payload.map(el => {
        return { title: el.value, id: el.key };
      });
    },
  },
  actions: {
    async apiGetExhibitions({ commit }, { type = "", sort = "newest_to_oldest ", page = "1" }) {
      let success = false;
      commit("setLoading", true);
      commit("setHasMorePages", false);
      let query = {
        type,
        sort,
        page,
      };
      let queryStr = "";
      for (let key in query) {
        if (query[key]) {
          queryStr += `${key}=${query[key]}&`;
        }
      }
      await Vue.axios
        .get(`/api/public/exhibitions?${queryStr}`)
        .then(res => res.data)
        .then(data => {
          success = true;
          commit("setExhibitions", { data: data.response, page });
          commit("setHasMorePages", data.hasMorePages);
        })
        .finally(() => {
          commit("setLoading", false);
        });
      return success;
    },

    async apiGetSpecificExhibition({ commit }, slug) {
      await Vue.axios.get(`/api/public/exhibitions/${slug}`).then(response => {
        commit("setExhibition", response?.data.response ?? {});
        commit("setFeature", response.data.has_artworks_and_artists);
      });
    },
    async apiGetFeaturedExhibition({ commit }, slug) {
      await Vue.axios
        .get(`/api/public/featured-exhibition`)
        .then(res => res.data)
        .then(data => {
          commit("setFeaturedExhibition", data.response);
        });
    },

    async apiGetFeatures(
      { commit },
      {
        key = "art",
        slug = "",
        materials = "",
        price_start = "",
        price_end = "",
        colors = "",
        sort = "title_a_z",
        page = "1",
        regions = "",
      }
    ) {
      let success = false;
      let url = "";
      commit("setFeatures", { features: [], pages: false });
      switch (key) {
        case "art": {
          let query = {
            materials,
            price_start,
            price_end,
            colors,
            sort,
            page,
          };
          let queryStr = "";
          for (let key in query) {
            if (query[key] != "") {
              queryStr += `${key}=${query[key]}&`;
            }
          }
          url = `/api/public/exhibitions/${slug}/artworks?${queryStr}`;
          break;
        }
        case "artist": {
          let query = {
            sort,
            regions,
            page,
          };
          let queryStr = "";
          for (let key in query) {
            if (query[key] != "") {
              queryStr += `${key}=${query[key]}&`;
            }
          }
          url = `/api/public/exhibitions/${slug}/artists?${queryStr}`;
          break;
        }
      }
      await Vue.axios
        .get(url)
        .then(res => res.data)
        .then(data => {
          success = true;
          commit("setFeatures", { features: data.response, pages: data.hasMorePages });
        });
      return success;
    },

    async apiGetFiltersBlock({ commit }, { list, type }) {
      await Vue.axios
        .get(`/api/public/filters?list=${list}&type=${type}`)
        .then(res => res.data)
        .then(data => {
          commit("setFilters", data?.response[0]?.items);
        });
    },
    async apiGetExhibitionTimeslotAvailability({ commit }, { id, date }) {
      let result = false;
      await Vue.axios
        .get(`/api/public/exhibition/timeslot-times?exhibition_id=${id}&date=${date}`)
        .then(res => res.data)
        .then(data => {
          result = true;
          commit("setTimeslotAvailability", data.response);
        });
      return result;
    },
    async apiGetExhibitionTimeslotDaysAvailability({ commit }, id) {
      let result = false;
      await Vue.axios
        .get(`/api/public/exhibition/timeslot-dates?exhibition_id=${id}`)
        .then(res => res.data)
        .then(data => {
          result = true;
          commit("setTimeslotDatesAvailability", data.response);
        });
      return result;
    },

    async apiPostRsvp({}, { exhibition_slug, data }) {
      let result = { success: false, error: null };
      await Vue.axios
        .post(`/api/public/exhibitions/${exhibition_slug}/rsvp`, data)
        .then(() => {
          result = { success: true, error: null };
        })
        .catch(e => {
          let response = e.response;
          if (response.status === 422) {
            result = { success: false, error: response.data.detail };
          }
        });
      return result;
    },
  },
};


<template>
  <label class="checkbox">
    <input type="checkbox" :checked="checked" :disabled="disabled" v-model="input" />
    <span class="checkbox__custom">
      <IconCheck v-if="icon && input"></IconCheck>
      <span v-if="!icon && input"></span>
    </span>
    <span class="checkbox__text" v-html="label"></span>
  </label>
</template>
<script>
import IconCheck from "@/components/icons/IconCheck.vue";
export default {
  components: { IconCheck },
  props: {
    label: String,
    checked: {
      type: [Boolean, Number],
      default: false,
    },
    icon: {
      type: Boolean,
      default: true,
    },
    id: Number,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    input: false,
  }),
  created() {
    this.input = this.checked;
  },
  watch: {
    checked(val) {
      this.input = val;
    },
    input(val) {
      val != this.checked && this.$emit("input", val);
      this.$emit("select", { value: val, id: this.id });
    },
  },
};
</script>
<style lang="scss" scoped>
.checkbox {
  display: inline-flex;
  align-items: center;

  cursor: pointer;
  transition: all 0.3s ease-in-out;
  @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
    font-size: 14px;
  }
  &--hidden {
    display: none;
  }
  &--disabled{
    opacity: 0.5;
    cursor: not-allowed;
  }
  &__custom {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15px;
    height: 15px;
    border-radius: 5px;
    border: 1px solid $lightGrey;
    margin-right: 8px;
    flex-shrink: 0;
  }
  &__text {
    @include type($fs: 14px, $lh: 1.5);
    color: $lightGrey;
  }
  input {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    visibility: hidden;
  }
  input:checked ~ &__custom {
    background: $blue;
    border-color: $blue;
    &::after {
      margin-right: 0px;
    }
  }
  &--white {
    .checkbox {
      &__custom {
        border: 1px solid $white;
      }
      &__text::v-deep > a,
      &__text {
        font-family: $fontFrutiger;
        font-size: 1.4rem;
        font-weight: 400;
        line-height: 1.4;
        letter-spacing: -0.5px;
        color: $white;
      }
    }
  }
  &--black {
    .checkbox {
      &__custom {
        width: 18px;
        height: 18px;
        border: 1px solid $black;
        padding: 3px;
        & span {
          width: 100%;
          height: 100%;
        }
      }
      &__text::v-deep > a,
      &__text {
        color: $black;
      }
    }
    input:checked ~ .checkbox__custom {
      background: transparent;
      border-color: $black;
      &::after {
        margin-right: 0px;
      }
      & span {
        background: $black;
        border-color: $black;
        border-radius: 3px;
      }
      svg::v-deep g{
        fill: $black;
      }
    }
  }
  &--gray {
    padding-left: 4px;
    .checkbox__custom {
      position: relative;
      &::after {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        content: "";
        width: 7.5px;
        height: 7.5px;
        border-radius: 1.5px;
        background: transparent;
        transition: all 0.3s linear;
      }
    }

    input:checked ~ .checkbox__custom {
      background: rgba(255, 255, 255, 0.2);
      border-color: $white;
      svg {
        display: none;
      }
      &::after {
        background: $white;
      }
    }
    .checkbox {
      &__custom {
        border-radius: 2px;
        border: 0.6px solid $white;
        background: rgba(255, 255, 255, 0.2);
      }
      &__text::v-deep > a,
      &__text {
        font-family: $fontFrutiger;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.18;
        letter-spacing: -0.5px;
        color: $white;
      }
    }
  }
}
</style>

